import Glide from '@glidejs/glide';
import {isMobile} from "@/utils/features";

export default function (dom, opts) {
    let options = {
        mainSliderSel: '.product-details__grid-item--image',
        sliderOverlaySel: '.image-overlay',
        openOverlay: '[data-open-overlay]',
        closeOverlay: '[data-close-overlay]',
        showOverlayCls: 'image-overlay-open',
        ...opts
    };

    let glideOptions = {
        type: 'carousel',
        perView: 1,
        gap: 0,
        keyboard: false,
        autoplay: false,
        swipeThreshold: 80,
        dragThreshold: 120,
        perTouch: 1,
        touchRatio: 0.5,
        touchAngle: 45,
        rewind: true,
    };

    const mainSlider = dom.querySelector(options.mainSliderSel);
    const sliderOverlay = dom.querySelector(options.sliderOverlaySel);
    const slideCountMain = [].slice.call(mainSlider.querySelectorAll('.glide__slide'), 0).length;
    const slideCountOverlay = [].slice.call(sliderOverlay.querySelectorAll('.glide__slide'), 0).length;
    const overlayOpen = [].slice.call(dom.querySelectorAll(options.openOverlay), 0);
    const overlayClose = [].slice.call(dom.querySelectorAll(options.closeOverlay), 0);

    const html = document.documentElement;
    const body = document.body;

    if (slideCountMain && slideCountMain == slideCountOverlay) {
        const glideMain = new Glide(mainSlider.querySelector('.glide'), glideOptions).mount();
        const glideOverlay = new Glide(sliderOverlay.querySelector('.glide'), glideOptions).mount();

        glideMain.on('run', (move) => {
            if( glideOverlay.index !== glideMain.index) {
                glideOverlay.go(`=${glideMain.index}`)
            }
        });
    }

    overlayOpen.forEach(item => {
        item.addEventListener('click', ()=> {
            html.classList.add(options.showOverlayCls);
        })
    });

    overlayClose.forEach(item => {
        item.addEventListener('click', ()=> {
            html.classList.remove(options.showOverlayCls);
        })
    });

    body.addEventListener('click', onOuterClick);

    function onOuterClick(e) {
        if (!isMobile() && !dom.contains(e.target)) {
            html.classList.remove(options.showOverlayCls);
        }
    }
}
