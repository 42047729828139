import {isMobile} from "../utils/features";

export default function (dom, opts) {
  let options = {
    ...opts
  };

  const header = document.body.querySelector("header")
  let headerHeight = header.clientHeight

  function calcHeaderHeight () {
    document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
  }

  if (!isMobile()) {

    calcHeaderHeight();

    window.addEventListener('resize', calcHeaderHeight)
  }
}
