
import map from 'rbtools-staticfile'
import htmlComponent from 'htmlcomponent'

htmlComponent.setStaticLoader(map)
htmlComponent.query(document)


document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

